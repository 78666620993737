table {
  border-spacing: 0;
  font-family: arial, sans-serif;
  width: 100%;
}

thead {
  margin: 0;
  background: #ffffff;
  z-index: 10;
  position: sticky;
  top: 0;
}

table thead th {
  padding: 19px 10px;
  margin: 0;
  border-left: 0;
  border-right: 0;
  text-align: left;
  box-shadow: inset 0 0 0 #e0e0e0, inset 0 -1px 0 #e0e0e0;
}

td {
  padding: 9px 10px;
}

.checkbox {
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
